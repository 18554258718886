import type { GetServerSideProps, NextPage } from "next";
import React from "react";
import styles from "../styles/general.module.css";

export type HomePageProps = {};

const HomePage: NextPage<HomePageProps> = ({}) => {
  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <h1 className={styles.title} lang="en">
          Welcome to Help Center
        </h1>
      </div>
    </div>
  );
};

export default HomePage;

export const getServerSideProps: GetServerSideProps<HomePageProps> =
  async function getServerSideProps() {
    return { props: {} };
  };
