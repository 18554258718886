import type { GetServerSideProps, NextPage } from "next";
import React from "react";
import HomePage, {
  HomePageProps,
  getServerSideProps as getHomeProps,
} from "./_home";
import CompanyPage, {
  CompanyPageProps,
  getServerSideProps as getCompanyProps,
} from "./[companySlug]";
import getAppSettings from "../lib/get-app-settings";
import authenticateViaUrlParameters from "../lib/authenticate-via-url-parameters";

export type IndexPageProps = HomePageProps | CompanyPageProps;

const isCompanyPage = (props: IndexPageProps): props is CompanyPageProps => {
  return "appSettings" in props;
};

const IndexPage: NextPage<IndexPageProps> = (props: IndexPageProps) => {
  if (isCompanyPage(props)) {
    return <CompanyPage {...props} />;
  } else {
    return <HomePage {...props} />;
  }
};

export default IndexPage;

export const getServerSideProps: GetServerSideProps<IndexPageProps> = async (
  context
) => {
  // this gets called when [company]
  // is not present in the URL
  // or company has been deleted
  // it gets called after 301 redirect
  // check [companySlug].tsx for more info
  const appSettings = await getAppSettings(context);

  if (appSettings?.customDomain) {
    const authResponse = await authenticateViaUrlParameters(
      appSettings,
      context
    );
    if (authResponse) return authResponse;

    const props = await getCompanyProps(context);
    return props;
  } else {
    return await getHomeProps(context);
  }
};
